import React, { useState } from "react";
import { FormInput } from "../../FormComponents/Input/Input";
import {Checkbox} from "../../FormComponents/Checkbox";
import {DynamicInputTable} from "../../FormComponents/Input/DynamicInputTable";
import {targetGroupTagTypes} from "../../../Constants/VOSS/targetGroups";
import {useTranslation} from "react-i18next";
import SkynetIcon from "../../Fundamentals/SkynetIcon";

export function TargetGroupsFormContent({ form, commonActions }) {
    const {t} = useTranslation();
    const [tagForm, setTagForm] = useState({tag: '', type: targetGroupTagTypes.exclusivity});
    const onChange = (value, key) => {
        commonActions.changeMultiFormField(form.id, key, value);
    };

    const onChangeConfiguration = (value, key) => {
        onChange({...form.data.configuration, [key]: value}, targetGroupTagTypes.exclusivity);
    }

    const onAddTag = () => {
        if (!tagForm.tag) return;

        const newTag = {tag: tagForm.tag, type: targetGroupTagTypes.exclusivity};
        onChange([...form.data.tags, newTag], "tags");

        setTagForm({tag: '', type: targetGroupTagTypes.exclusivity});
    }

    const onDeleteTag = (index) => {
        const updatedTags = form.data.tags.filter((_, i) => i !== index);
        onChange(updatedTags, "tags");
    }

    if (!form) {
        return null;
    }

    const headRows = <>
        <th>{t('common.name')}</th>
        <th>{t('targetgroupsformcontent.type')}</th>
    </>

    const inputRow = <>
        <td className="p-12">
            <FormInput value={tagForm.tag}
                       placeholder={t('common.name')} onChange={(value) => setTagForm({...tagForm, tag: value})}/>
        </td>
        <td>
            {tagForm.type}
        </td>
    </>

    function currentRow(tag) {
        return <>
            <td>{tag.tag}</td>
            <td>{tag.type}</td>
        </>
    }

    return (<>
        <fieldset className="d-grid col-4 gap-16">
            <FormInput
                label={t('common.name')}
                value={form?.data.name}
                onChange={(value) => onChange(value, 'name')}
                required={true}
            />
            <Checkbox label={t('targetgroupsformcontent.default')} checked={form?.data.isDefault}
                      onChange={(value) => onChange(value, 'isDefault')}/>
        </fieldset>
        <fieldset className="d-grid col-4 gap-16">
            <legend>{t('targetgroupsformcontent.configuration')}</legend>
            <Checkbox label={t('targetgroupsformcontent.organizationnumberrequired')}
                      checked={form.data?.configuration?.organizationNumberRequired}
                      onChange={(value) => onChangeConfiguration(value, 'organizationNumberRequired')}/>
            <Checkbox label={t('targetgroupsformcontent.organizationnumberunique')}
                      checked={form.data?.configuration?.organizationNumberUnique}
                      onChange={(value) => onChangeConfiguration(value, 'organizationNumberUnique')}/>
            <Checkbox label={t('targetgroupsformcontent.customernumberrequiredtobeempty')}
                      checked={form.data?.configuration?.customerNumberRequiredToBeEmpty}
                      onChange={(value) => onChangeConfiguration(value, 'customerNumberRequiredToBeEmpty')}/>
            <Checkbox label={t('targetgroupsformcontent.customeraddressrequired')}
                      checked={form.data?.configuration?.customerAddressRequired}
                      onChange={(value) => onChangeConfiguration(value, 'customerAddressRequired')}/>
            <Checkbox label={t('targetgroupsformcontent.contactpersonrequired')}
                      checked={form.data?.configuration?.contactPersonRequired}
                      onChange={(value) => onChangeConfiguration(value, 'contactPersonRequired')}/>
            <Checkbox label={t('targetgroupsformcontent.expirydaterequired')}
                      checked={form.data?.configuration?.expiryDateRequired} ky
                      onChange={(value) => onChangeConfiguration(value, 'expiryDateRequired')}/>
            <Checkbox label={t('targetgroupsformcontent.excludecustomerfrominvoicefile')}
                      checked={form.data?.configuration?.excludeCustomerFromInvoiceFile}
                      onChange={(value) => onChangeConfiguration(value, 'excludeCustomerFromInvoiceFile')}/>
        </fieldset>
        <fieldset className="d-grid col-4 gap-16">
            <DynamicInputTable headRows={headRows} inputRow={inputRow} current={form.data.tags}
                               currentRow={currentRow} onAdd={onAddTag} disableAdd={!tagForm.tag}
                               onDelete={onDeleteTag} title={t('targetgroupsformcontent.tags')}
                               currentTitle={t('targetgroupsformcontent.currenttags')}/>
        </fieldset>
    </>);
}